<template lang="pug">
	article#localEvento
		figure.banner_evento
			img(:src="banner ? banner : require('@images/backgrounds/bgLocal.jpg')")
		section#infoLocal
			.wrapper
				router-link(:to="{name: 'evento'}").voltar
					img(:src="require('@images/icones/arrow.png')")
					span Voltar para o evento
				h2 Local do evento
				div.informacoes
					div.texto
						div(v-html="local.descricao", v-if="local")
						span Endereço 
						strong {{ enderecoCompleto }}
						span Telefone 
						a(:href="`tel:${local.telefone}`", v-if="local") {{ local.telefone }}
					Mapa(:lat="local.latitude", :lng="local.longitude", v-if="local").mapa
				router-link(:to="{name: 'eventos'}").eventos Ir para eventos
</template>

<script>
import Mapa from '@vue/sections/local/mapa/Mapa'

export default {
	components: {
		Mapa
	},
	data() {
		return {
			local: null,
			banner: null
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.$axios
				.get(`api/eventos/${to.params.slug}/local`)
				.then(response => {
					vm.local = response.data.local
					vm.banner = response.data.local_topo
				})
				.catch(() => {
					vm.$router.replace({ name: '404' })
				})
		})
	},
	computed: {
		enderecoCompleto() {
			if (!this.local)
				return ''
			return `${this.local.endereco} - ${this.local.cidade_nome}/${this.local.uf}`
		}
	},
}
</script>

<style lang="stylus" scoped src="./Local.styl"></style>