<template lang="pug">
	div#mapa(ref="mapa")
</template>

<script>
export default {
    props: {
        lat: {
            type: String,
            required: true
        },
        lng: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            map: null,
            marker: null,
            centerlatlng: null,
            icon: require("@images/icones/icone_pin.png"),
            style: null
        }
    },
    created() {
        let script = document.createElement('script');
        script.src= `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&language=pt-BR&region=BR`;
        script.onload = () => {
            // eslint-disable-next-line
            this.centerlatlng = new google.maps.LatLng(this.$props.lat, this.$props.lng);
            this.createStyle();
            this.createMap()
                .createMarker()
        }
        document.querySelector('body').append(script);
    },
    methods: {
        createMap() {
            // eslint-disable-next-line
			this.map = new google.maps.Map(this.$refs.mapa, {
                center: this.centerlatlng,
                zoom: 16,
				mapTypeControl: false,
                zoomControl: true,
                styles: this.style,
                zoomControlOptions: {
                    // eslint-disable-next-line
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
            });
			return this;
		},
		createMarker() {
            // eslint-disable-next-line
			this.marker = new google.maps.Marker({
                position: this.centerlatlng,
                map: this.map,
                icon: this.icon
            });
		},
        createStyle() {
			this.style = [
                {
                    "featureType": "administrative.locality",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffd800"
                        },
                        {
                            "saturation": "0"
                        },
                        {
                            "lightness": "0"
                        },
                        {
                            "gamma": "10.00"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
		}
    },
}
</script>

<style lang="stylus" scoped src="./Mapa.styl"></style>